.landing-content {
	overflow: hidden;
	max-width: 1300px;
	margin: auto;
	padding: 0;
	text-align: center;
}

.landing-content img,
.landing-content video {
	border-radius: 5px;
}

.img-background {
	border-radius: 5px;
	background-color: #FFF;
	padding: 2px;
	text-align: center;
}

.text-container {
	text-align: left;
}

.page-title {
	padding-bottom: 1em;
	border-bottom: 1px solid grey;
	text-align: left;
}

.page-description {
	display: block;
	margin-bottom: 2.5em;
	font-size: 1.1em;
}

video {
	width: 100%;
}

.landing-signup {
	font-weight: 500;
}

.page {
	padding: 6em 0;
	height: auto;
}

/* PAGE ENROLLEMENT */

.enrollement-title {
	margin: 0.3em 0 2em 0px;
	text-align: center;
	font-size: 4em;
	font-weight: 500;
}

.title-number {
	color: #F05454;
	float: left;
	font-size: 5.6em;
	font-weight: 700;
	padding-right: 0.4em;
	line-height: 1em;
}

/* LAST PAGE */

.page4 {
	background-color: #000;
	height: 69em;
	margin: 6em 0 1px 0;
}

.value-title {
	margin-top: 4em;
	text-Align: left;
	color: #fff;
	font-size: 4.5em;
	font-weight: 600;
}

.value-signup {
	margin-top: 10%;
	font-size: 2.5em;
	line-height: 1.6em;
	font-weight: 600;
	height: 100%;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {
}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.landing-content {
		padding: 0 40px;
	}

	.value-title {
		font-size: 4.1em;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.enrollement-title {
		font-size: 3.5em;
		font-weight: 500;
	}

	.value-title {
		font-size: 3.6em;
	}
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.landing-content {
		padding: 0 18px;
	}

	.value-title {
		margin-top: 11vh;
		font-size: 2.4em;
		text-align: center;
	}

	.img-background {
		border-radius: 5px;
		padding: 1px;
	}

	.landing-content img,
	.landing-content video {
		border-radius: 5px;
	}

	.page {
		padding: 3em 0;
	}

	.landing-signup {
		margin-bottom: 24px;
	}

	.text-container {
		margin: auto;
	}

	.enrollement-title {
		font-size: 2.4em;
		font-weight: 600;
		margin: 1em 0 1.7em 0;
		padding: 0 0.4em;
	}

	.title-number {
		padding-right: 0.2em;
		line-height: 1em;
	}

	.page4 {
		height: 55em;
	}

	.value-title {
		margin-top: 200px;
		font-size: 250%;
	}

	.value-signup {
		margin-top: 5em;
		font-size: 1.3em;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}