/**

TODO : Add less + Ant Design less @import '~antd/dist/antd.less'; 

#F05454 / rgb(240, 84, 84, 1) : Primary color AntD
#C62828 : Primary color on white background
#DD2D2D : Primary color on black background
rgba(232, 232, 232, 0.6): Light grey

*/


/* HEADER *****************************************************************************************************************************************/

.header {
    /* Managed in the code
    position: fixed; */
    z-index: 100;
    width: 100%;
    /* MARK: Header height */
    height: 75px;
    background-color: #000000;
    padding: 0px 140px;
}

.logo {
    width: 75px;
    float: left;
    margin: 14px 0 0 40px;
}

.navigation {
    float: right;
    padding: 0px;
    background-color: #000000;
    font-size: 1.1em;
    font-weight: 450;
}

.navigation .ant-menu-item,
.navigation .ant-menu-submenu {
    height: 4px;
    /* MARK: Header height */
    line-height: 75px;
    margin-inline: 10px;
    padding: 0 5px;
}

.ant-menu-submenu-placement-bottomLeft {
    padding-top: 1px;
}

.tickerSearchBoxPopup {
    /* Override element.style */
    min-width: 210px !important;
}


/* LAYOUT ******************************************************************************************************************************************/

.site-layout {
    width: 100% !important;
}

.sider-left {
    margin-right: 3.5em;
    background-color: #fff !important;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
    border-color: rgb(210, 210, 215, 0.5);
    border-radius: 5px;
}

.site-layout-content {
    margin: 170px 140px;
    min-height: 70vh;
}

.site-layout-content-fixed {
    margin: 170px auto;
    width: 1100px;
    min-height: 70vh;
}

.site-layout-content-blog {
    width: 680px;
}

/* ANCHOR MENU */

.anchor-menu {
    position: fixed;
    margin-right: 2.2em;
    top: 27%;
    /* Position on the right side */
    right: 0;
}


/* CONTENT *****************************************************************************************************************************************/

.none-color-link {
    color: #000;
}

.none-color-link:hover {
    color: #F05454;
}

.breadcrumb {
    margin-bottom: 3em;
}

.reading {
    font-size: 1.05rem;
    line-height: 2rem;
}

/* TITLES */

.page-title1 {
    margin: 0 0 30px 0px !important;
}

.page-title1-logo {
    vertical-align: middle;
    margin: 0 5px 5px 5px;
}

.ai-logo {
    border: 1px solid #000;
    padding: 0.1em 0.3em;
}

.separator {
    width: 1em;
    border-top: 1px solid;
}

.text-red {
    color: #C62828;
}

.quote {
    font-size: 1.5rem;
}

/* GRID */

.grid-bottom {
    margin-bottom: 2.2rem;
}

/* LEFT MENU */

.left-menu {
    background-color: #fff !important;
    padding: 5px 2px;
}

.left-menu-item {
    border: 1px solid rgba(240, 240, 240, 1);
}

/* BUTTONS */

.button-icon {
    font-size: 1.3em !important;
}

.button-login {
    font-weight: bold;
}

.button-signup,
.button-logout {
    border: 0.1rem solid white;
    color: white;
    background-color: transparent;
}

.float-button {
    z-index: 210;
    right: 35px;
}

/* SIGNALS */

.signal {
    transform: scale(1.6);
    width: 11px;
}

/* TABLE */

.table-header-center {
    text-align: center !important;
}

.stylish-cell-top {
    border-top: 1px solid grey !important;
}

.table-row-pointer {
    cursor: pointer;
}

.legend-box {
    text-align: left;
    margin-top: 1.5em;
}

.legend-text {
    color: rgba(160, 160, 160);
    padding-top: 5px;
    border-top: 1.5px solid rgba(220, 220, 220);
}

/* CAROUSEL */

.carousel-dots li button {
    background-color: #F05454 !important;
}

.carousel-dots .slick-active button {
    background-color: #F05454 !important;
}

/* CHARTS */

.card-chart-candle .ant-card-body,
.modal-chart-candle .ant-modal-content {
    padding-top: 22px;
    padding-bottom: 10px !important;
}

.card-chart .ant-card-body {
    padding-top: 13px;
}

.chart-legend-box {
    text-align: right;
    margin-top: 1em;
}

.chart-legend-text {
    color: rgb(160, 160, 160);
    padding-top: 5px;
    border-top: 1.5px solid rgb(220, 220, 220);
    font-size: 0.8rem;
}

.chart-context-menu {
    border-radius: 5px;
}

/* CARD */

.card {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
    border-color: rgb(210, 210, 215, 0.5);
}

.card .ant-card-body {}

.card .ant-card-head {
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.15rem;
    background-color: #fff;
    border: none;
}

.card-divider {
    color: grey !important;
    font-size: 0.95em !important;
    border-color: rgba(200, 200, 200, 0.4) !important;
}

/* MODAL */

.modal-title {
    margin-left: 0.3em;
    font-size: 1.7em;
}

.modal-paragraph {
    margin-top: 2em;
}

/* FOOTER ******************************************************************************************************************************************/

.footer {
    background-color: #000000;
    text-align: center;
    padding: 60px 0 0 0;
}

.footer-content {
    color: #FFF;
    margin: auto;
    width: 100%;
    padding: 0px 140px;
    text-align: left;
}

.footer-content-top {
    height: auto;
    overflow: auto;
    border-bottom: 1px #FFF solid;
    padding-bottom: 50px;
}

.footer-link-block {
    width: 270px;
    float: right;
    color: 'white'
}

.footer-legal-block {
    float: right;
}

.footer-list-header {
    color: #F05454;
    font-weight: bold;
    font-size: larger;
}

.footer-list-item {
    padding: 1.5px 0 !important;
}

.footer-content a {
    color: rgba(255, 255, 255, 0.65) !important;
}

.footer-content a:hover {
    color: rgba(255, 255, 255, 1) !important;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* Large tablets */
@media only screen and (max-width: 1210px) {

    .header,
    .footer-content {
        padding: 0px 3em;
    }

    .site-layout-content {
        margin: 170px 3em;
    }
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {
    .footer-content {
        padding: 0px 30px 20px 30px;
        width: auto;
    }
}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

    /* HEADER */
    .header {
        padding: 0px 10px;
    }

    .logo {
        width: 70px;
        margin: 20px 0 0 13px;
    }

    .button-navigation {
        display: flex;
        float: right;
        margin: 1.6rem 10px 0 0;
    }

    .navigation-mobile {
        background-color: #000000;
    }

    .navigation .ant-menu-item {
        line-height: 75px;
        margin-inline: 10px;
        /* padding: 0 5px; */
    }

    /* CONTENT */

    .site-layout-content,
    .site-layout-content-fixed {
        margin: 105px 5px;
        min-height: 76vh;
        width: auto;
    }

    /* GRID */

    .grid-bottom {
        margin-bottom: 0.8rem;
    }

    .float-button {
        right: 25px;
        bottom: 20px;
    }

    .table {
        overflow-x: auto;
        /* Add horizontal scroll if necessary */
    }

    /* CHARTS */

    .modal-chart-candle .ant-modal-content {
        padding: 1.5em 1em 10px 1em;
    }

    .card .ant-card-body {
        padding: 1.5em 1em;
    }

    .card .ant-card-head {
        padding: 0 1em;
    }

    .chart-legend-text {
        font-size: 0.55rem;
    }

    /* FOOTER */
    .footer-social-block {
        margin-top: 40px;
    }

    .footer-content {
        padding: 0px 18px;
    }

    .footer-link-block {
        width: 270px;
        margin: auto;
        float: none;
    }

    .footer-legal-block {
        float: none;
        margin-bottom: 15px;
    }
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {

    .menu-collapse .ant-collapse-header {
        padding: 0.2em 0 !important;
    }

    .menu-collapse .ant-collapse-content-box {
        padding: 1em 0 !important;
    }
}