.items-container {
	text-align: left;
}

/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

}

/* Large tablets */
@media only screen and (max-width: 1210px) {
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {
	.items-container {
		width: 20rem;
		margin: auto;
		text-align: left;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}