.new-user-content {
    background-color: #000;
	margin-bottom: 1px;
    color: #FFF;
	width: 100%;
	height: 90vh;
	text-align: center;
	overflow: hidden;
}

.text-writer {
    margin: 0 auto;
    position: relative;
	color: #fff;
	top: 21vh;
	font-size: 70px;
}

.new-user-startup-searchbox {
	margin: 50px auto 0;
	position: relative;
	display: block;
	width: 600px;
}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {
	.text-writer {
		margin: 0 auto;
		position: relative;
		color: #fff;
		top: 21vh;
		font-size: 250%;
	}

	.new-user-startup-searchbox {
		width: 300px;
	}
}