.textarea {
	width: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 8px;
    border: 1px solid #F05454;
    border-radius: 4px;
    resize: none;
}

.textarea:focus {
	border: 1px solid #F05454;
  	outline: none !important; /* Remove color added by AntD */
}

.editing-container {
    position: relative;
}

.legend {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 12px;
    color: #888;
}