/* .carousel {} */

.testimonial-author {
	margin-bottom: 1em;
	font-weight: bold;
}

.testimonial-text {
	font-size: 1rem;
}

.testimonial-rate {
	margin-top: 1em;
}

/* .tweet a {
	color: #C62828;
} */
.tweet :global(.react-tweet-theme) {
	--tweet-body-font-size: 1rem;
	box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
    border-color: rgb(210, 210, 215, 0.5);
  }

/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {}

/* Large tablets */
@media only screen and (max-width: 1210px) {}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}