.container {
	margin-top: 75px;	/* Header height */
	padding: 13vh 0 17vh 10rem;
	text-align: center;
	background: linear-gradient(0deg, rgba(232, 232, 232, 0.6), white);
}

.content {
	margin: 0 auto;
	overflow: hidden;
	max-width: 2260px;
}

.block-left-content {
	margin: 0 auto 6px auto;
	padding-left: 6px;
	max-width: 41rem;
	text-align: left;
}

#candle {
	width: 1200px;
}

.title {
	text-align: left;
	margin: 0 0 3rem 0;
	color: #000;
	font-size: 5.5em;
	font-weight: 500;
}

.page-description {
	display: block;
	margin: 1rem 0 2.5rem 0;
	max-width: 1100px;
	font-size: 1.6em;
	color: grey;
}

.signup {
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 500;
}

.assets-list {
	text-align: center;
	margin-top: 4.9rem;
	font-size: 3.9em;
	font-weight: 450;
	color: grey;
}

.carousel {
	margin: 3.5rem auto 0 auto;
	max-width: 41rem;
	cursor: pointer;
}

.card-meta {
	font-size: 1.1rem;
	padding: 0.5em;
	text-align: left;
}

.card-metric {
	display: inline-block;
	font-weight: bold;
	width: 2.8em;
	text-align: right;
	margin-right: 0.3em;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

	.container {
		padding: 12vh 0 11vh 10rem;
	}

	.title {
		font-size: 5em;
	}
}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.container {
		padding-top: 9vh;
	}

	.title {
		font-size: 4.5em;
	}

	.page-description {
		font-size: 1.4em;
	}

	.assets-list {
		margin-top: 3.5rem;
		font-size: 3.4em;
	}

	#candle {
		width: 68rem;
	}

	.carousel {
		margin-top: 3.5rem;
	}

	.card-meta {
		padding: 0.45em;
		font-size: 1rem;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.container {
		padding-top: 8vh;
	}

	.title {
		font-size: 4em;
		margin-bottom: 2rem;
	}

	.page-description {
		font-size: 1.3em;
	}

	.signup {
		font-size: 0.98rem;
	}

	.assets-list {
		margin-top: 2.8rem;
		font-size: 3em;
	}

	.carousel {
		margin-top: 2em;
	}

	.card-meta {
		font-size: 1rem;
		padding: 0.2em;
	}

	#candle {
		width: 58rem;
	}
}

/* Large tablets */
@media only screen and (max-width: 1210px) {
	.container {
		padding-left: 5rem;
	}

    .title {
		font-size: 3.6em;
		margin-bottom: 2rem;
	}

	.page-description {
		font-size: 1.18em;
	}

	.signup {
		font-size: 0.95rem;
	}

	.assets-list {
		margin-top: 3rem;
		font-size: 2.4em;
	}

	.carousel {
		margin-top: 3em;
	}

	.card-meta {
		font-size: 0.92rem;
		padding: 0.1em;
	}

	#candle {
		width: 58rem;
	}
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.container {
		padding: 3rem 0 3.5rem 0;
	}

	.content {
		margin: 0 10px;
		text-align: center;
	}

	.block-left-content {
		margin: auto;
		padding-left: 0px;
		width: 100%;
		text-align: center;
	}

	.title {
		width: 20rem;
		font-size: 3.3em;
		font-weight: 550;
		text-align: center;
		margin-bottom: 3.5rem;
	}

	.page-description {
		width: 20rem;
		font-size: 1.2em;
		color: grey;
	}

	.assets-list {
		margin-top: 1.2rem;
		font-size: 1.6em;
	}

	.carousel {
		margin-top: 3em;
		max-width: 17rem;
	}

	.card-meta {
		padding: 0em;
	}

	#candle {
		margin-top: 1rem;
		width: 100%;
	}

	.signup {
		margin-top: 2rem;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}