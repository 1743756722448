#pricing-layout-content {
    width: 1500px !important;
}

#pricing-page-title {
    margin: 0 0 0.5em 0px !important;
    text-align: center;
    font-size: 4.1rem;
	font-weight: 500;
}

/* Ensure all Col components take full height */
#pricing-layout-content .ant-row {
    display: flex;
    align-items: stretch;
}

/* Ensure each column stretches */
#pricing-layout-content .ant-col {
    display: flex;
}

/* Ensure each Card fills the column height */
#pricing-layout-content .card-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-content .ant-card-head {
    padding: 20px 1rem;
    text-align: center;
}

.card-content:hover,
.default-selected-card {
    border: 2px solid #F05454;
}

.card-content ul {
    font-size: 1.15em;
}

.card-button {
    text-align: center;
    margin-bottom: 2.5em;
}

.price {
    font-size: 2em;
}

.price-year,
.description {
    color: grey;
    font-size: 0.95em;
    white-space: normal;
}

.feature-icon {
    font-size: 1.1em
}

.title-description {
    color: grey;
    margin-bottom: 3.3em;
    font-size: 1.8em;
    font-weight: 500;
    text-align: center;
}

.try-pro {
    color: #F05454;
    font-size: 1.8em;
    font-weight: 500;
    text-align: center;
    font-style: italic;
}

.switch-content {
    font-size: 1.7em;
    font-weight: 500;
    margin: 1em 0 1.7em 4.7em;
}

.pricing-switch {
    margin-bottom: 0.2em;
}

.pricing-legend {
    display: block;
    margin-top: 4em;
}

.order-summary-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {
    #pricing-layout-content {
        width: 1300px !important;
    }

    #pricing-page-title {
        font-size: 3.7rem;
    }
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {
    #pricing-layout-content {
        width: 1280px !important;
    }
}

/* Large tablets */
@media only screen and (max-width: 1210px) {
    #pricing-layout-content {
        width: 1150px !important;
    }
}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

    #pricing-layout-content {
        width: auto !important;
        padding: 0 5px;
    }

    #pricing-page-title {
        margin: 2rem 0 2rem 0px !important;
        font-size: 3.5em;
    }

    .title-description, .try-pro {
        font-size: 1.4em;
        
    }

    .switch-content {
        margin: 1em 0 1.4em 0em;
        font-size: 1.7em;
    }

    .card-content {
        margin-bottom: 2em;
    }
}