.container {
	margin: 19vh auto;
	padding: 0 0 0 10rem;
	text-align: center;
}

.content {
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
}

.block-left-content {
	margin: 0 auto 6px auto;
	padding-left: 6px;
	max-width: 39rem;
	text-align: left;
}

#candle {
	width: 1400px;
}

.title {
	text-align: left;
	margin: 1vh 0 5.5rem 0;
	color: #000;
	font-size: 5em;
	font-weight: 500;
}

.page-description {
	display: block;
	margin: 1rem 0 2rem 0;
	max-width: 1100px;
	font-size: 1.2em;
	color: grey;
}

.signup {
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 500;
}

.carousel {
	margin: 7em auto 0 auto;
	max-width: 39rem;
	cursor: pointer;
}

.card-title,
.card-content {
	font-size: 1rem;
}

.card-metric {
	display: inline-block;
	font-weight: bold;
	width: 2.8em;
	text-align: right;
	margin-right: 0.3em;
}


/* RESPONSIVE **************************************************************************************************************************************/

/* MacBook Pro 16inch and below */
@media only screen and (max-width: 1792px) {

	.title {
		font-size: 4.5em;
	}

	#candle {
		width: 1200px;
	}

	.card-title,
	.card-content {
		font-size: 1rem;
	}
}

/* MacBook Pro 14inch and below */
@media only screen and (max-width: 1520px) {

	.title {
		font-size: 4.1em;
		margin-bottom: 5rem;
	}

	#candle {
		width: 1100px;
	}

	.carousel {
		margin-top: 6em;
	}
}

/* XX-Large devices (larger desktops, MacBook Air, 1400px and below) */
@media only screen and (max-width: 1440px) {

	.title {
		font-size: 3.4em;
		margin-bottom: 2rem;
	}

	.carousel {
		margin-top: 3em;
	}

	#candle {
		width: 900px;
	}
}

/* X-Large devices (large desktops, 1200px and below) */
@media only screen and (max-width: 1200px) {}

/* Large devices (desktops, 992px and below) */
@media only screen and (max-width: 992px) {

	.container {
		margin: 7vh auto 0 auto;
		padding: 0 0 10vh 0;
	}

	.content {
		text-align: center;
	}

	.block-left-content {
		margin: auto;
		padding-left: 0px;
		width: 100%;
		text-align: center;
	}

	.title {
		font-size: 2.9em;
		font-weight: 600;
		text-align: center;
		margin-bottom: 4rem;
	}

	.carousel {
		max-width: 17rem;
	}

	#candle {
		width: 100%;
	}

	.signup {
		margin-top: 2rem;
	}
}

/* Medium devices (tablets, 768px and below) */
@media only screen and (max-width: 768px) {}